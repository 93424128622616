.sidebar {
    width: 240px;
    height: calc(100vh - 20px);
    background: var(--white-color);


}
.sidebar-item {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
    padding-left: 20px;

    }

    .sidebar-active {
        position: relative;
        background-color: rgba(133, 130, 254, .2);
        color: var(--theme-main-color);
        font-weight: 600;
        padding-left: 16px;
        border-left: 4px solid var(--theme-main-color);
    }

    .sidebar-active::before {
        height: 100px;
        background-color: red;
        width: 100px;
    }
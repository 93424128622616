.finput {
    border: 1px solid var(--border-color);
    height: 45px;
    width: 100%;
    border-radius: 4px;
    padding: 16px 10px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    transition: all ease 0.3s;
    letter-spacing: 0.3px;
}

.finput:focus {
    border-color: #777;
}

.finput::placeholder {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
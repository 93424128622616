.center-modal-wrapper {
    background: rgba(0, 0, 0, 0.2);
    inset: 0px;
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
}

.center-modal-wrapper-inside {
    background-color: var(--white-color);
    height: 85%;
    width: 90%;
    border-radius: 12px;
    margin: 0px auto;
    transition: width 0.3s ease;

}

.right-modal-wrapper {
    background: rgba(0, 0, 0, 0.2);
    inset: 0px;
    position: fixed;
    z-index: 100;
}

.right-modal-wrapper-inside {
    background-color: var(--white-color);
    width: 650px;
    height: 100%;
    right: 0px;
    position: fixed;
    transition: transform .5s transform .5s cubic-bezier(0.23, 1, 0.32, 1);
}

.modal-header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid var(--border-color);
}

.cross-hover {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.cross-hover:hover {
    background-color: var(--background-color);
}
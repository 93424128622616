.project {
    width: 150px;
    background-color: var(--background-color);
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
    transition: all ease 0.3s;
    display: flex;
    justify-content: space-between;
}

.project:hover {
    transition: all ease 0.3s;
    background-color: var(--background-dark);
}
.fc {
    height: inherit;
    overflow-y: hidden;
}

.fc .fc-toolbar.fc-header-toolbar {
    display: none !important;
}

.fc-col-header {
    position: fixed;
    z-index: 100;
    background-color: #fff;
}

.fc-scrollgrid-sync-table {
    margin-top: 23px !important;
}

.fc .fc-daygrid-event {
    margin-top: 10px;
}

.fc-h-event .fc-event-main-frame {
    height: 25px;
    align-items: center;
    cursor: pointer;
}

.fc-daygrid-event {
    border-radius: 12px;
    padding-left: 10px;
    font-weight: 600;
}
.box-shadow {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 20px -7px rgba(0, 0, 0, 0.2);
}

.button {
    background-color: var(--button-color);
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
    color: var(--white-color);
    font-weight: 600;
    cursor: pointer;
    border-radius: 8px;
    font-size: 18px;

}
.container {
    padding: 25px;
    min-height: calc(100vh - 65px);
    overflow-y: auto;
}
.flex {
    display: flex;
}

.flex-v-center {
    display: flex;
    align-items: center;
}

.flex-h-center {
    display: flex;
    justify-content: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container-1 {
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 15px -7px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
}

.box-wrapper-shadow {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 15px -7px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
}

.box-wrapper-shadow:hover {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 20px -7px rgba(0, 0, 0, 0.3);
}

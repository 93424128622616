.top-navigation-item {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 18px;
    padding: 18px 2px;
}

.top-navigation-active-item {
    color: var(--theme-main-color);
    font-weight: 600;
    border-bottom: 2px solid var(--theme-main-color);
    padding-bottom: 16px;
}
.line-navigation-item {
    margin-right: 15px;
    cursor: pointer;
    font-size: 18px;
    padding: 18px 2px;
    color: var(--text-light-color);
}

.line-navigation-active-item {
    color: var(--theme-main-color);
    font-weight: 600;
    border-bottom: 2px solid var(--theme-main-color);
    padding-bottom: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Sans Pro',
  sans-serif;
  box-sizing: border-box !important;
  --text-color: #2a2e34;
  overflow: hidden;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #f2f4f7;
  --theme-main-color:#006EFA;
  --theme-main-color-light:#dfdbfb;
  --border-color: #e9ebf0;
  --text-color: #2a2e34;
  --text-light-color: #656f7d;
  --button-color: #006EFA;
  --white-color: #fff;
  --background-dark:#dfe4ec;
  --success-color:#00C851;
  --danger-color:#ff4444;
  --warning-color:#ffbb33;
  --other-color:#4285F4;


}

:root.light {
  --border-color: #e9ebf0;
}

:root.dark {
  --border-color: #e9ebf0;
}
.suggestion-modal {
  box-shadow: 0 .2rem 0.4rem rgba(1, 1, 1, .06) !important;
  position: absolute;
  top: 50px;
  border: 1px solid var(--border-color);
  height: 200px;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.suggestion-modal-item {
  min-height: 40px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 13px;
  background: #fff;
}